const url = 'https://api2-admin.sellerfaces.com.br'

export const environment = {
    url,
    apiUrl: `${url}/api/`,
    apiUrlUpload: `${url}/api/upload/uploads/upload`,
    apiUrlDownload: `${url}/api/upload/uploads/download`,
    meLink: 'https://melhorenvio.com.br/oauth/authorize',
    version: '0.6.8',
    templateLink: 'https://s3.sellerfaces.com.br/prod-sf-image-assets/ImportProductTemplate.xlsx',
    isDevelopment: false
}



